import isPromise from "../../utils/isPromise";
import parseError from "../../utils/parseError";
import { toastError } from "../../utils/toast";

export default function asyncError(store: any) {
  return (next: (arg0: any) => Promise<any>) => (action: { payload: any }) => {
    if (!isPromise(action.payload)) {
      return next(action);
    }
    return next(action).catch((error: any) => {
      const errorMessage = parseError(error);
      toastError(errorMessage);
      throw error;
    });
  };
}
