import {
  LOGIN_USER,
  REGISTER_USER,
  RESEND_VERIFICATION_EMAIL,
  RESEND_CONFIRMATION_CODE,
  SET_REGISTRATION_EMAIL,
  SEND_RESET_PASSWORD_EMAIL,
  RESET_PASSWORD,
  CLEAR_LOGIN_DATA,
  SEND_PHONE_VERIFICATION_OTP,
  VERIFY_PHONE_NUMBER,
  SET_PHONE_VERIFICATION_TOKEN,
  SET_VERIFICATION_PHONE_NUMBER,
  CLEAR_ERROR,
  VERIFY_EMAIL,
  LOGOUT_USER
} from "./actionTypes";
import { AuthReducerState } from "./type";

import parseError from "../../utils/parseError";

const INITIAL_STATE: AuthReducerState = {
  loading: false,
  loginData: null,
  registerData: null,
  phoneVerificationData: null,
  registrationEmail: null,
  verificationPhoneNumber: null,
  phoneVerificationToken: null,
  error: null
};

export default function auth(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case LOGIN_USER.pending:
      return {
        ...state,
        error: null,
        loading: true
      };
    case LOGIN_USER.rejected:
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    case LOGIN_USER.fulfilled:
      return {
        ...state,
        loading: false,
        loginData: action.payload.data
      };
    case REGISTER_USER.pending:
      return {
        ...state,
        error: null,
        loading: true
      };
    case REGISTER_USER.rejected:
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    case REGISTER_USER.fulfilled:
      return {
        ...state,
        loading: false,
        registerData: action.payload.data
      };
    case RESEND_VERIFICATION_EMAIL.pending:
      return {
        ...state,
        error: null,
        loading: true
      };
    case RESEND_VERIFICATION_EMAIL.rejected:
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    case RESEND_VERIFICATION_EMAIL.fulfilled:
      return {
        ...state,
        loading: false
      };
    case RESEND_CONFIRMATION_CODE.pending:
      return {
        ...state,
        loading: true
      };
    case RESEND_CONFIRMATION_CODE.rejected:
      return {
        ...state,
        loading: false
      };
    case RESEND_CONFIRMATION_CODE.fulfilled:
      return {
        ...state,
        loading: false
      };
    case VERIFY_EMAIL.pending:
      return {
        ...state,
        loading: true
      };
    case VERIFY_EMAIL.rejected:
      return {
        ...state,
        loading: false
      };
    case VERIFY_EMAIL.fulfilled:
      return {
        ...state,
        loading: false
      };
    case SEND_RESET_PASSWORD_EMAIL.pending:
      return {
        ...state,
        loading: true
      };
    case SEND_RESET_PASSWORD_EMAIL.rejected:
      return {
        ...state,
        loading: false
      };
    case SEND_RESET_PASSWORD_EMAIL.fulfilled:
      return {
        ...state,
        loading: false
      };
    case RESET_PASSWORD.pending:
      return {
        ...state,
        loading: true
      };
    case RESET_PASSWORD.rejected:
      return {
        ...state,
        loading: false
      };
    case RESET_PASSWORD.fulfilled:
      return {
        ...state,
        loading: false
      };
    case SEND_PHONE_VERIFICATION_OTP.pending:
      return {
        ...state,
        loading: true
      };
    case SEND_PHONE_VERIFICATION_OTP.rejected:
      return {
        ...state,
        loading: false
      };
    case SEND_PHONE_VERIFICATION_OTP.fulfilled:
      return {
        ...state,
        loading: false,
        phoneVerificationData: action.payload.data
      };
    case VERIFY_PHONE_NUMBER.pending:
      return {
        ...state,
        loading: true
      };
    case VERIFY_PHONE_NUMBER.rejected:
      return {
        ...state,
        loading: false
      };
    case VERIFY_PHONE_NUMBER.fulfilled:
      return {
        ...state,
        loading: false,
        phoneVerificationData: null,
        phoneVerificationToken: null
      };
    case SET_VERIFICATION_PHONE_NUMBER:
      return {
        ...state,
        verificationPhoneNumber: action.payload
      };
    case SET_REGISTRATION_EMAIL:
      return {
        ...state,
        registrationEmail: action.payload.email
      };
    case SET_PHONE_VERIFICATION_TOKEN:
      return {
        ...state,
        phoneVerificationToken: action.payload.token
      };
    case CLEAR_LOGIN_DATA:
      return {
        ...state,
        loginData: null
      };
    case CLEAR_ERROR.fulfilled:
      return {
        ...state,
        error: null
      };
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
