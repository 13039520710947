import { lazy } from "react";

const appRoutes = [
  {
    path: "/dashboard",
    component: lazy(() => import("../views/Dashboard")),
    exact: true
  },
  {
    path: "/profile",
    component: lazy(() => import("../views/Profile")),
    exact: true
  },
  {
    path: "/transactions",
    component: lazy(() => import("../views/Transactions")),
    exact: true
  }
];

export default appRoutes;
