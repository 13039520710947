import React from "react";
import Notification from "../components/Notification";
import { toast, ToastContainerProps } from "react-toastify";

interface Props extends ToastContainerProps {
  message?: string;
  closeToast?: () => void;
}

function toastSuccess(
  title: string | number,
  { message, closeToast, ...options }: Props = {}
): void {
  toast(
    <Notification
      status="success"
      title={title}
      message={message}
      closeToast={closeToast}
    />,
    options
  );
}

function toastError(
  title: string | number,
  { message, closeToast, ...options }: Props = {}
): void {
  toast(
    <Notification
      status="error"
      title={title}
      message={message}
      closeToast={closeToast}
    />,
    options
  );
}

export { toastSuccess, toastError };
