import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import LoaderBox from "../components/LoaderBox";
import appRoutes from "./app";

const DashboardRoutesWrapper = lazy(() =>
  import("../components/DashboardRoutesWrapper")
);
const DashboardViewWrapper = lazy(() =>
  import("../components/DashboardViewWrapper")
);
const Login = lazy(() => import("../views/Login"));
const Register = lazy(() => import("../views/Register"));
const AuthResponse = lazy(() => import("../views/AuthResponse"));
const VerifyEmail = lazy(() => import("../views/VerifyEmail"));
const VerifyPhone = lazy(() => import("../views/VerifyPhone"));
const ResetPassword = lazy(() => import("../views/ResetPassword"));

const Routes = () => {
  return (
    <Router>
      <React.Suspense fallback={<LoaderBox />}>
        <Switch>
          <Redirect from="/" to="/login" exact />
          <Route path="/" component={() => <h1>Home</h1>} exact={true} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} exact={true} />
          <Route path="/verify-email" component={VerifyEmail} />
          <Route path="/auth/response" component={AuthResponse} />
          <Route path="/verify-phone" component={VerifyPhone} />
          <Route path="/change-password" component={ResetPassword} />
          <DashboardRoutesWrapper>
            <Switch>
              <DashboardViewWrapper>
                <React.Suspense fallback={<LoaderBox />}>
                  <Switch>
                    {appRoutes.map(route => (
                      <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                      />
                    ))}
                  </Switch>
                </React.Suspense>
              </DashboardViewWrapper>
            </Switch>
          </DashboardRoutesWrapper>
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default Routes;
