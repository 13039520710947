import React from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/";
import { toast } from "react-toastify";
import LoaderBox from "./components/LoaderBox";
import "react-toastify/dist/ReactToastify.css";

const ToastConfig = {
  className: "toast__container",
  toastClassName: "toast__toast",
  bodyClassName: "toast__body",
  hideProgressBar: true,
  closeButton: false,
  position: toast.POSITION.TOP_CENTER
};

toast.configure(ToastConfig);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoaderBox />} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
};

export default App;
