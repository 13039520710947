import React from "react";

import Message from "../styles/blocks/Message";
import P from "../styles/elements/P";

import { ReactComponent as CloseIcon } from "../assets/img/close-icon.svg";
import { ReactComponent as SuccessIcon } from "../assets/img/checkmark-green.svg";
import { ReactComponent as ErrorIcon } from "../assets/img/not-resolved.svg";

type statusType = "success" | "error" | "warning";

interface Props {
  status: statusType;
  title: string | number;
  message?: string;
  closeToast?: () => void;
}

const Notification: React.FC<Props> = ({
  status,
  title,
  message,
  closeToast
}) => {
  const getStatusIcon = (status: statusType) => {
    switch (status) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      default:
        return null;
    }
  };
  return (
    <Message.Container status={status}>
      <Message.Icon>{getStatusIcon(status)}</Message.Icon>
      <Message.Content>
        {title && (
          <P big semiBold>
            {title}
          </P>
        )}
        {message && <P medium>{message}</P>}
      </Message.Content>
      <Message.Close>
        <button onClick={closeToast}>
          <CloseIcon />
        </button>
      </Message.Close>
    </Message.Container>
  );
};

export default Notification;
