import actionTypes from "../actionTypes";

export const LOGIN_USER = actionTypes("LOGIN_USER");

export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER = actionTypes("REGISTER_USER");

export const RESEND_VERIFICATION_EMAIL = actionTypes(
  "RESEND_VERIFICATION_EMAIL"
);

export const RESEND_CONFIRMATION_CODE = actionTypes("RESEND_CONFIRMATION_CODE");

export const VERIFY_EMAIL = actionTypes("VERIFY_EMAIL");

export const SET_REGISTRATION_EMAIL = "SET_REGISTRATION_EMAIL";

export const SEND_RESET_PASSWORD_EMAIL = actionTypes(
  "SEND_RESET_PASSWORD_EMAIL"
);

export const SET_VERIFICATION_PHONE_NUMBER = "SET_VERIFICATION_PHONE_NUMBER";

export const SET_PHONE_VERIFICATION_TOKEN = "SET_PHONE_VERIFICATION_TOKEN";

export const SEND_PHONE_VERIFICATION_OTP = actionTypes(
  "SEND_PHONE_VERIFICATION_OTP"
);

export const VERIFY_PHONE_NUMBER = actionTypes("VERIFY_PHONE_NUMBER");

export const RESET_PASSWORD = actionTypes("RESET_PASSWORD");

export const CLEAR_LOGIN_DATA = "CLEAR_LOGIN_DATA";

export const CLEAR_ERROR = actionTypes("CLEAR_ERROR");
