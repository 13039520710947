import { FETCH_USER, CONFIRM_USER, SET_APP_MODE } from "./actionTypes";
import { LOGOUT_USER } from "../auth/actionTypes";
import parseError from "../../utils/parseError";
import { UserReducerState } from "./type";

const INITIAL_STATE: UserReducerState = {
  user: null,
  token: null,
  confirmingUser: false,
  fetching: false,
  error: null,
  mode: "test"
};

export default function user(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case FETCH_USER.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_USER.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_USER.fulfilled:
      return {
        ...state,
        fetching: false,
        user: action.payload.data
      };

    case CONFIRM_USER.pending:
      return {
        ...state,
        confirmingUser: true
      };
    case CONFIRM_USER.rejected:
      return {
        ...state,
        confirmingUser: false
      };
    case CONFIRM_USER.fulfilled:
      return {
        ...state,
        confirmingUser: false,
        token: action.payload.data.access_token
      };

    case SET_APP_MODE.fulfilled:
      return {
        ...state,
        mode: action.payload
      };

    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
        mode: state.mode
      };

    default:
      return state;
  }
}
