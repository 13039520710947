import { combineReducers } from "redux";

import authReducer from "./auth";
import userReducer from "./user";

import { AuthReducerState } from "./auth/type";
import { UserReducerState } from "./user/type";

export type Store = {
  auth: AuthReducerState;
  user: UserReducerState;
};

const reducers = combineReducers<Store>({
  auth: authReducer,
  user: userReducer
});

export default reducers;
