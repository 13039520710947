import { AxiosError } from "axios";

const parseError = (err: AxiosError) => {
  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.message
  ) {
    return err.response.data.error.message;
  } else if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message;
  } else if (err.response && err.response.data) {
    return err.response.data;
  } else if (err.message) {
    return err.message;
  } else {
    return "Error Occured";
  }
};

export default parseError;
